/* 
.header-image {
  background: url("../img/shadow.jpg");
  position: relative;
  height: 20vh;
  max-width: 100%;
  filter: opacity(0.9);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
} */

.rg-container {
    padding: 1% 5% 5%;
}

.review-guest-container {
    padding: 0 5% 2%;
}

.thumbs-title {
    padding: 2% 0;
    text-align: center;
}

.incident-date {
    width: 40%;
}

.thumbs-icon {
}
/* 
.thumbs {
  padding: 2% 0;
  text-align: center;
}
.thumbsUp,
.thumbsDown {
  font-size: 65px;
  padding: 3%;
  color: #e3bf31;
}

.thumbsUp:hover,
.thumbsDown:hover {
  color: #d7cc8d;
  cursor: pointer;
} */

.guest-review-box {
    border-bottom: 3px solid black;
    padding: 2% 0;
    background-color: rgb(213, 213, 213);
}

@media screen and (min-width: 570px) {
}

@media screen and (min-width: 768px) {
    .rg-container {
        padding: 1% 8% 5%;
    }

    .thumbs-title {
        padding: 3% 0;
    }

    .thumbs {
        text-align: center;
        padding: 2% 22% 5%;
    }

    .thumbsUp,
    .thumbsDown {
        font-size: 85px;
    }

    .review-guest-uploads {
        padding-bottom: 5%;
    }
}

@media screen and (min-width: 992px) {
    .thumbs-title {
        padding: 3% 0;
    }

    .thumbs {
        text-align: center;
        padding: 0% 22% 2%;
    }

    .thumbsUp,
    .thumbsDown {
        font-size: 100px;
    }

    .review-guest-uploads {
        padding-bottom: 2%;
    }
}

@media screen and (min-width: 1200px) {
}

@media screen and (min-width: 1400px) {
    .review-guest-container {
        padding: 2% 8% 0%;
    }

    .review-guest-header p {
        padding: 0 20% 5% 0;
    }

    .thumbs-title {
        padding: 3% 0 1%;
    }

    .thumbs {
        padding: 0 28% 2%;
    }

    .thumbsUp,
    .thumbsDown {
        font-size: 110px;
    }

    .review-guest-uploads {
        padding-bottom: 0;
    }
}

@media screen and (max-width: 566px) and (max-height: 768px) and (orientation: landscape) {
}

.reviewType .form-check-inline .form-check-input {
    margin-top: 4px;
}
