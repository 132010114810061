* {
    font-family: 'Mulish', sans-serif;
}

:root {
    --primary: #0f0e0e;
    --secondary: #737373;
}

body,
p,
a,
li,
ul {
    padding: 0;
    text-decoration: none;
}

a,
p {
    color: black;
    font-size: 1em;
    font-weight: normal;
}

a:hover {
    color: #737373;
    text-decoration: none;
    /* font-weight: bold; */
    cursor: pointer;
}

a,
button {
    font-family: 'Poppins', sans-serif;
}

li {
    list-style-type: none;
}

body {
    overflow-x: hidden;
    color: var(--primary);
}

.main-button {
    padding: 6px 48px;

    border-radius: 5px;
    background-color: var(--primary);
    color: #ffffff;
    font-size: calc(1rem +0.2vw);
    display: inline-block;
}

.main-button:hover {
    background-color: #ffffff;
    color: #000000;
    border-color: #000000;
}

h1,
h2,
h3,
h4 {
    font-weight: normal;
    line-height: 1.2em;
}

p,
a,
li {
    line-height: 1.4em;
    /* font-size: 1.1em; */
}

h1,
h2 {
    font-size: calc(2rem + 2.9vw);
    font-family: 'mulish', sans-serif;
    letter-spacing: -0.04em;
}

h3 {
    font-size: calc(1.1rem + 3.5vw);
    font-family: 'mulish', serif;
    letter-spacing: -0.02em;
}

h4 {
    font-size: calc(1.1rem + 2.2vw);
}

.text-red,
.ErrorMessage {
    color: red;
    font-weight: bold;
    font-size: 14px;
}

.text-green {
    color: #31c25e;
}

.user-input {
    padding-bottom: 3%;
}

.icon-button:hover {
    cursor: pointer;
}

.likes-num {
    padding: 0 5% 0 0;
    display: inline-block;
}

.red-flag {
    display: inline-block;

    --fa-animation-duration: 0s;
}

.red-flag:hover {
    display: inline-block;
    color: rgb(165, 14, 14);
    --fa-animation-duration: 1s;
    cursor: pointer;
}

.fa-face-grin-tongue-squint {
    --fa-animation-duration: 0s;
    padding: 0 1% 0 0;
}
.fa-face-grin-tongue-squint:hover {
    --fa-animation-duration: 1s;
    color: #31c25e;
    cursor: pointer;
}

.result-sent p {
    color: #31c25e;
    font-weight: bold;
}

.manage-button a {
    color: white;
}

/* .manage-button:hover {
  background-color: #4f755b;
}

.manage-button {
  background-color: #379154;
} */
@media screen and (min-width: 570px) {
    h3 {
        font-size: calc(1.1rem + 3vw);
    }

    h4 {
        font-size: calc(1.1rem + 2vw);
    }
}

@media screen and (min-width: 768px) {
    h3 {
        font-size: calc(1.1rem + 2.4vw);
    }

    h4 {
        font-size: calc(1.1rem + 1.8vw);
    }
}

@media screen and (min-width: 992px) {
    h3 {
        font-size: calc(1.1rem + 2.6vw);
    }

    h4 {
        font-size: calc(1.1rem + 1.4vw);
    }
}

@media screen and (min-width: 1200px) {
    h3 {
        font-size: calc(1.1rem + 2.2vw);
    }

    h4 {
        font-size: calc(1.1rem + 1.2vw);
    }
}

.rdt_TableCol,
.rdt_TableCell {
    font-size: 16px;
}
