.create-account-container {
    padding: 0 8%;
}

.show-pass {
    float: right;
    font-size: 13px;
    background-color: unset;
    border: none;
}

input[type='firstName'],
[type='lastName'],
[type='Title'],
[type='Address'],
[type='state'],
[type='city'] {
    text-transform: capitalize;
}

.thankyou-message {
    text-align: center;
    padding: 1% 15% 0;
}
