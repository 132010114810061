.jumbotron-container {
    padding: 0;
}

.jumbotron-parallax {
    background: url('../img/bailey-alexander-pkIJXMezi_E-unsplash.jpg');

    position: relative;
    height: 80vh;
    max-width: 100%;
    filter: opacity(0.9);
    background-position: -28em;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
}

.jumbotron-slogan {
    margin: 0;
    position: absolute;
    top: 100px;

    width: 100%;
    padding-left: 5%;
    text-align: center;

    /* padding: 0 2% 0 5%; */
}

.jumbotron-slogan h1 {
    padding: 15px 0;
}

/* .slogan-bg {
    height: 50%;
    background: radial-gradient(circle, rgba(242, 239, 239, 0.958) 20%, rgba(144, 52, 52, 0) 100%) no-repeat;

    filter: blur(3px);
    position: relative;
} */

@media screen and (min-width: 425px) {
    .jumbotron-parallax {
        background: url('../img/bailey-alexander-pkIJXMezi_E-unsplash.jpg');
        position: relative;
        height: 80vh;
        max-width: 100%;
        filter: opacity(0.9);
        background-position: center right -150px;
        background-size: cover;
        background-attachment: fixed;
        background-repeat: no-repeat;
    }

    .jumbotron-slogan {
        top: 100px;
        padding: 0 12% 0 5%;
    }

    /* .slogan-bg {
        background: radial-gradient(circle, rgba(242, 239, 239, 0.865) 20%, rgba(144, 52, 52, 0) 70%) no-repeat;
        top: 5%;
    } */
}

@media screen and (min-width: 568px) {
    .jumbotron-slogan {
        top: 100px;
        padding: 0 5% 0 5%;
    }

    .jumbotron-parallax {
        background: url('../img/bailey-alexander-pkIJXMezi_E-unsplash.jpg');
        position: relative;
        height: 80vh;
        max-width: 100%;
        filter: opacity(0.9);
        background-position: center right;
        background-size: cover;
        background-attachment: fixed;
        background-repeat: no-repeat;
    }
}
@media screen and (min-width: 768px) {
    .jumbotron-parallax {
        background: url('../img/bailey-alexander-pkIJXMezi_E-unsplash.jpg');
        position: relative;
        height: 80vh;
        max-width: 100%;
        filter: opacity(0.9);
        background-position: center;
        background-size: cover;
        background-attachment: fixed;
        background-repeat: no-repeat;
    }

    .jumbotron-slogan {
        top: 100px;
        text-align: center;
        padding: 0 15% 5%;
    }

    /* .slogan-bg {
        height: 70%;
        background: radial-gradient(circle, rgba(244, 244, 244, 0.707) 20%, rgba(144, 52, 52, 0) 50%) no-repeat;
        top: 0;
        filter: blur(5px);
        position: relative;
    } */
    /* .jumbotron-slogan {
      padding: 5% 0;
    } */
}

@media screen and (min-width: 961px) {
    .jumbotron-slogan {
        top: 100px;
        padding: 0 15% 5%;
    }
}

@media screen and (min-width: 992px) {
    .jumbotron-parallax {
        background: url('../img/bailey-alexander-pkIJXMezi_E-unsplash.jpg');
        position: relative;
        height: 80vh;
        max-width: 100%;
        filter: opacity(0.9);
        background-position: bottom;
        background-size: cover;
        background-attachment: fixed;
        background-repeat: no-repeat;
    }

    .jumbotron-slogan {
        top: 170px;
        text-align: center;
        padding: 0 15% 5%;
    }

    /* .slogan-bg {
        height: 70%;
        background: radial-gradient(circle, rgba(232, 220, 220, 0.652) 20%, rgba(144, 52, 52, 0) 40%) no-repeat;
        top: 5%;
        filter: blur(10px);
        position: relative;
    } */
}

@media screen and (min-width: 1200px) {
    .jumbotron-parallax {
        background: url('../img/bailey-alexander-pkIJXMezi_E-unsplash.jpg');
        position: relative;
        height: 80vh;
        max-width: 100%;
        filter: opacity(0.9);
        background-position: bottom;
        background-size: cover;
        background-attachment: fixed;
        background-repeat: no-repeat;
    }

    .jumbotron-slogan {
        top: 170px;
        text-align: center;
        padding: 0 15% 5%;
    }
}

@media screen and (min-width: 1400px) {
    .jumbotron-parallax {
        background: url('../img/bailey-alexander-pkIJXMezi_E-unsplash.jpg');
        position: relative;
        height: 80vh;
        max-width: 100%;
        filter: opacity(0.9);
        background-position: center -150px;
        background-size: cover;
        background-attachment: fixed;
        background-repeat: no-repeat;
    }
}

@media only screen and (max-height: 575.98px) and (orientation: landscape) {
    .jumbotron-parallax {
        background: url('../img/bailey-alexander-pkIJXMezi_E-unsplash.jpg');
        position: relative;
        height: 80vh;
        max-width: 100%;
        filter: opacity(0.9);
        background-position: center right;
        background-size: cover;
        background-attachment: fixed;
        background-repeat: no-repeat;
    }

    .jumbotron-slogan {
        top: 50px;
        padding: 0 16% 0;
        text-align: center;
    }

    /* .slogan-bg {
        height: 70%;
        background: radial-gradient(circle, rgba(232, 220, 220, 0.652) 10%, rgba(144, 52, 52, 0) 40%) no-repeat;
        top: 5%;
        filter: blur(10px);
        position: relative;
    } */
}
