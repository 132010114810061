.admin-contact {
    padding: 2% 8%;
}
@media screen and (min-width: 992px) {
    .admin-contact {
        padding: 2% 15%;
    }
}

@media screen and (min-width: 1400px) {
    .admin-contact {
        padding: 2% 20%;
    }
}
