.navbar {
    padding: 2.5% 5% 1%;
    background-color: unset;
}

.navbar-toggler {
    border: none;
}

.nav-link {
    font-size: 20px;
}

.nav-link:focus {
    font-weight: bold;
}

.nav-item {
    background-color: unset;
    border: none;
}

.link-to-pages {
    font-size: 1.2em;
}

.link-to-pages:hover {
    font-size: 1.2em;
    font-weight: bold;
}

@media screen and (min-width: 992px) {
    .link-to-pages {
        display: inline;
    }

    .dropdown-menu {
        display: inline;
    }
}
