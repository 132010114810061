.reset-password {
    padding: 32% 0%;
    height: 90vh;
}

.reset-password-form {
    padding: 8% 0;
}

.reset-button-container {
    margin-top: 2%;
}

.reset {
    padding-top: 5%;
}

@media screen and (min-width: 570px) {
    .reset-form {
        padding: 0 10%;
    }
}

/* @media screen and (min-width: 768px) {
    .reset-password {
      padding: 22% 12%;
      height: 90vh;
    }
  } */

@media screen and (min-width: 992px) {
    .reset-password {
        padding: 15% 12%;
        height: 90vh;
    }

    .reset-form {
        padding: 0 12%;
    }
}

@media screen and (min-width: 1200px) {
    .reset-form {
        padding: 0 16%;
    }
}

@media screen and (min-width: 1400px) {
}

@media screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 812px) {
    .reset-password {
        padding: 5% 6%;
        height: 100vh;
        background-color: violet;
    }
}
