.review-details-container {
    padding: 1% 5% 0;
}

.add-review {
    padding: 1% 5% 0;
}

.guest-name {
    font-size: 1.5em;
    font-weight: bold;
    text-transform: uppercase;
}
