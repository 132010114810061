.about-container {
    padding: 4% 5% 5%;
}

.about-intro {
    /* padding: 0 10% 5% 3%; */
}
.about-intro h3 {
    /* font-weight: bold; */
}

.about-intro p {
    /* font-size: 0.938rem; */
    /* padding-right: 20%; */
    padding: 2% 6% 5% 0;
}

/* Video  */
.about-video {
    padding-top: 10%;
}

.about-container {
    /* padding: 2% 5%; */
}

.embed-container {
}

.video-container {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media screen and (min-width: 570px) {
    .about-container {
        padding: 5% 4% 5%;
    }

    .about-intro h3 {
    }

    .about-intro p {
        padding: 2% 14% 4% 0;
        line-height: 27.8px;
    }
}

@media screen and (min-width: 768px) {
    .about-intro h3 {
    }
}

@media screen and (min-width: 992px) {
    .about-container {
        padding: 3% 4% 5%;
    }

    .about-intro h3 {
        line-height: 60px;
    }

    .about-intro p {
        padding: 2% 5% 4% 0;
        line-height: 27.8px;
    }

    .about-video {
        padding-top: 5%;
    }
}

@media screen and (min-width: 1200px) {
    .about-intro h3 {
    }
}

@media screen and (min-width: 1400px) {
    .about-intro h3 {
    }

    .about-video {
        padding-top: 2%;
    }
}

@media screen and (max-width: 566px) and (max-height: 768px) and (orientation: landscape) {
    .about-intro {
    }
}
