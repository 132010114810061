.user-reviews-box {
    line-height: 23.5px;
    padding: 8%;
}

.user-review p {
    font-family: 'saira', sans-serif;
    font-weight: bolder;
}

.left-col {
    background: url('../img/diane-wuttke--37ysvsiUbk-unsplash.jpg');
    position: relative;
    height: 80vh;
    max-width: 100%;
    filter: opacity(0.9);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
}
/** media queries **/
@media screen and (min-width: 570px) {
}

@media screen and (min-width: 768px) {
    .user-review-container {
        color: #090909;
        /* padding: 3% 0; */
        /* background-color: #69796b; */
    }

    .user-reviews-box {
        line-height: 23.5px;
        padding: 2%;
    }

    .user-review p {
        font-family: 'saira', sans-serif;
        font-weight: bolder;
    }

    .left-col {
        margin: 2% 2%;
        background: url('../img/diane-wuttke--37ysvsiUbk-unsplash.jpg');
        position: relative;
        height: 80vh;
        max-width: 100%;
        filter: opacity(0.9);
        background-position: bottom;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

@media screen and (min-width: 992px) {
    .user-reviews-box {
        padding: 2% 0;
    }

    .user-review {
        padding: 3% 5%;
    }
}

@media screen and (min-width: 1400px) {
    .user-reviews-box {
        padding: 5% 0;
    }

    .user-review {
        padding: 3% 10%;
    }
}
