.accordion-container {
    margin: 1% 5% 10%;
}

.faq-container p {
    /* font-size: 1.3rem; */
}

.accordion-body {
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-button {
    /* font-size: 1.25rem; */
    line-height: 1.58;
    /* font-weight: bold; */
    font-size: 19px;
}
.accordion-button::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300808'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

@media screen and (min-width: 570px) {
    .faq-container {
        margin: 5% 10% 10%;
    }
}

@media screen and (min-width: 768px) {
    .accordion-container {
        margin: 1% 15% 10%;
    }
}

@media screen and (min-width: 992px) {
}

@media screen and (min-width: 1200px) {
}

@media screen and (min-width: 1400px) {
}

@media screen and (max-width: 566px) and (max-height: 768px) and (orientation: landscape) {
}
