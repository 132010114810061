.latest-container {
    padding: 3% 5% 6%;
}

.review-box {
    padding: 0 2%;
}

.card-subtitle {
    text-transform: capitalize;
    font-style: italic;
}
.card-title {
    text-transform: capitalize;
}
.otro-blockquote-2 {
    font-size: 1em;
    width: 100%;
    margin: 20px auto;
    padding: 1.2em 0px 1.2em 12px;
    line-height: 1.4;
    position: relative;
}

.otro-blockquote-2::before {
    font-family: 'Mulish';
    content: '\201C';
    font-size: 3em;
    position: absolute;
    left: -10px;
    top: -1px;
}

.otro-blockquote-2::after {
    content: '';
}

.otro-blockquote-2 span {
    display: block;
    color: #333333;
    font-style: normal;
    font-weight: bold;
    margin-top: 1em;
}

.otro-blockquote {
    font-size: 1em;
    width: 100%;
    margin: 20px auto;
    padding: 1.2em 0px 1.2em 12px;
    line-height: 1.4;
    position: relative;
}

.otro-blockquote::before {
    font-family: 'Mulish';
    content: '\201C';
    font-size: 3em;
    position: absolute;
    left: -10px;
    top: -1px;
}

.otro-blockquote::after {
    content: '';
}

.otro-blockquote span {
    display: block;
    color: #333333;
    font-style: normal;
    font-weight: bold;
    margin-top: 1em;
}

@media screen and (min-width: 768px) {
    .review-text-2 {
        text-align: right;
        padding-left: 8%;
    }

    .otro-blockquote-2 {
        margin: 30px auto;
        padding: 1em 45px 1em 55px;
    }

    .otro-blockquote-2::before {
        left: 42px;
        top: -10px;
    }

    .otro-blockquote {
        margin: 30px auto;
        padding: 1em 30px 1em 45px;
    }

    .otro-blockquote::before {
        left: 15px;
        top: -10px;
    }
}

@media screen and (min-width: 992px) {
    .review-text-2 {
        text-align: right;
        padding-left: 18%;
    }

    .otro-blockquote-2 {
        padding: 1em 55px 1em 30px;
    }

    .otro-blockquote-2::before {
        left: 2px;
        top: -10px;
    }

    .otro-blockquote {
        padding: 1em 90px 1em 55px;
    }

    .otro-blockquote::before {
        left: 15px;
        top: -10px;
    }
}
@media screen and (min-width: 1024px) {
    .review-text-2 {
        text-align: right;
    }

    .otro-blockquote-2 {
        padding: 1em 55px 1em 90px;
    }

    .otro-blockquote-2::before {
        left: 60px;
        top: -10px;
    }

    .otro-blockquote {
        padding: 1em 150px 1em 55px;
    }

    .otro-blockquote::before {
        left: 15px;
        top: -10px;
    }
}

@media screen and (min-width: 1440px) {
    .otro-blockquote-2 {
        margin: 10px auto;
        padding: 1em 45px 1em 105px;
    }

    .otro-blockquote-2::before {
        left: 120px;
        top: -10px;
    }

    .otro-blockquote {
        margin: 10px auto;
        padding: 1em 250px 1em 45px;
    }

    .otro-blockquote::before {
        left: 15px;
        top: -10px;
    }
}

.content {
    padding-top: 30px;
}

/* Testimonials */
.testimonials blockquote {
    background: #f8f8f8 none repeat scroll 0 0;
    border: medium none;
    color: #666;
    display: block;
    font-size: 14px;
    line-height: 20px;
    padding: 15px;
    position: relative;
}
.testimonials blockquote::before {
    width: 0;
    height: 0;
    right: 0;
    bottom: 0;
    content: ' ';
    display: block;
    position: absolute;
    border-bottom: 20px solid #fff;
    border-right: 0 solid transparent;
    border-left: 15px solid transparent;
    border-left-style: inset; /*FF fixes*/
    border-bottom-style: inset; /*FF fixes*/
}
/* .testimonials blockquote::after {
  width: 0;
  height: 0;
  right: 0;
  bottom: 0;
  content: " ";
  display: block;
  position: absolute;
  border-style: solid;
  border-width: 20px 20px 0 0;
  border-color: #e63f0c transparent transparent transparent;
} */
.testimonials .carousel-info img {
    border: 1px solid #f5f5f5;
    border-radius: 150px !important;
    height: 75px;
    padding: 3px;
    width: 75px;
}
.testimonials .carousel-info {
    overflow: hidden;
}
.testimonials .carousel-info img {
    margin-right: 15px;
}
.testimonials .carousel-info span {
    display: block;
}
/* .testimonials span.testimonials-name {
  color: #e6400c;
  font-size: 16px;
  background-color: ;
  font-weight: 300;
  margin: 23px 0 7px;
} */
.testimonials span.testimonials-post {
    color: #656565;
    font-size: 12px;
}
.content {
    padding-top: 30px;
}

/* Testimonials */
.testimonials blockquote {
    background: #f8f8f8 none repeat scroll 0 0;
    border: medium none;
    color: #666;
    display: block;
    font-size: 14px;
    line-height: 20px;
    padding: 15px;
    position: relative;
}
.testimonials blockquote::before {
    width: 0;
    height: 0;
    right: 0;
    bottom: 0;
    content: ' ';
    display: block;
    position: absolute;
    border-bottom: 20px solid #fff;
    border-right: 0 solid transparent;
    border-left: 15px solid transparent;
    border-left-style: inset; /*FF fixes*/
    border-bottom-style: inset; /*FF fixes*/
}
/* .testimonials blockquote::after {
  width: 0;
  height: 0;
  right: 0;
  bottom: 0;
  content: " ";
  display: block;
  position: absolute;
  border-style: solid;
  border-width: 20px 20px 0 0;
  background-color: violet;
  border-color: #504a49 transparent transparent transparent;
} */

.testimonials .carousel-info {
    overflow: hidden;
}
.testimonials .carousel-info img {
    margin-right: 15px;
}
.testimonials .carousel-info span {
    display: block;
}
/* .testimonials span.testimonials-name {
  color: #444242;
  font-size: 16px;
  font-weight: 300;
  margin: 23px 0 7px;
  background-color: rgb(57, 168, 112);
} */
.testimonials span.testimonials-post {
    color: #646464;
    font-size: 12px;
}
.header-row {
    margin-top: 20px;
}
.exact-filters {
    margin-top: 10px;
}

.record-count {
    padding-top: 12px;
}

.filter-container {
    position: relative;
}
.filter-input,
.page-size {
    width: 100%;
    display: inline;
    display: none;
    margin-bottom: 2%;
}

.clear-filter {
    position: absolute;
    right: 10px;
    display: none;
    top: -5px;
}
