.search-guest-container {
    padding: 1% 4% 4%;
}

.search-guest-title h1 {
    padding: 4% 0;
    /* font-size: 3.5rem; */
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
    letter-spacing: 0.5px;
}
.search-guest-right {
    margin-top: 2%;
}

.clickable {
    text-decoration: none;
    color: unset;
    width: 100%;
}

.review-container {
    margin: 0 0 5%;
    border-radius: 3px;
}

.review-container:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.15);
}

.icon-button {
    font-size: 18px;
}
/* .test {
    background-color: turquoise;
  } */

.guest-name h5 {
    font-size: 1.8rem;
}

/* .negative-report-text {
  color: rgb(201, 50, 50);
}

.positive-report-text {
  color: rgb(42, 165, 101);
} */

.select-guest a {
    text-decoration: none;
    color: unset;
}

.review-guest-button,
.search-guest-button {
    border-radius: 0;
    background-color: #323232;
    color: white;
    font-size: 1.2em;
    margin: 2% 0;
    width: 100%;
}

.add-guest-button {
    border-radius: 0;
    background-color: #323232;
    color: white;
    font-size: 1.2em;
    margin: 5% 0;
    width: 50%;
}

.add-guest-button:hover {
    color: white;
    background-color: #0b0b0b;
}

/**scroll to top button **/
.top-to-btm {
    position: relative;
}
.icon-position {
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 20;
}
.icon-style {
    background-color: #4584b5;
    border: 2px solid #fff;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    color: #fff;
    cursor: pointer;

    transition: all 0.5s ease-in-out;
}
.icon-style:hover {
    animation: none;
    background: #fff;
    color: #163d5a;
    border: 2px solid #163d5a;
}
@keyframes movebtn {
    0% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(20px);
    }
    50% {
        transform: translateY(0px);
    }
    75% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0px);
    }
}

/** media queries **/
@media screen and (min-width: 570px) {
    .search-guest-title h1 {
        padding: 0;
        font-size: 2.2rem;
        text-align: center;
    }

    .review-guest-button,
    .search-guest-button {
        width: 100%;
    }
}

@media screen and (min-width: 768px) {
    .search-guest-title h1 {
        padding: 0 0 4% 0;
        font-size: 2.4rem;
        text-align: left;
    }
}

@media screen and (min-width: 992px) {
}

@media screen and (min-width: 1200px) {
}

@media screen and (min-width: 1400px) {
}

@media only screen and (max-height: 575.98px) and (orientation: landscape) {
}

/* 
@media (min-width: 1024px) {
  .search-guest-title h1 {
    padding-bottom: 10%;
    font-size: 3.5rem;
  }
}

.search-result-container {
  padding: 2%;
}
.guest-item {
  text-transform: capitalize;
  width: 100%;
}

.guest-item a {
  text-decoration: none;
  color: #000000;
}

.search-results {
  padding: 2%;
}
.search-results:hover {
  background-color: #e4e4e484;
}

.btn-dark {
  border-radius: 0;
} */
