.pp-container {
    padding: 5%;
}

.pp-container ul li {
    list-style: circle;
    margin-left: 5%;
    padding: 0.5% 0;
}

.pp-title h3 {
    text-align: center;
}

.pp-heading {
    padding: 5% 0 0;
}

.pp-email {
    /* padding-left: 3%; */
    color: #484747;
}

.pp-footer-text {
    text-align: right;
    padding-top: 3%;
}
/** media queries **/
@media screen and (min-width: 570px) {
}

@media screen and (min-width: 768px) {
    .pp-container {
        padding: 2% 18%;
    }
}

@media screen and (min-width: 1200px) {
    .pp-container {
        padding: 2% 22%;
    }
}

@media screen and (min-width: 1400px) {
    .pp-container {
        padding: 2% 26%;
    }
}

@media only screen and (max-height: 575.98px) and (orientation: landscape) {
}
