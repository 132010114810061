.selection-container {
    padding: 2% 5% 5%;
}

.password-input {
    padding: 0 !important;
}

a.active.nav-link {
    background-color: #111111 !important;
    color: white;
}

a.active.nav-link h5 {
    color: white;
}

.tab-item h5 {
    color: #111111;
}
