.homepage-image {
    background: url('../img/bluecouch.jpg');
    /* height: auto; */
    opacity: 95%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 0vh;
    visibility: hidden;
}

.homepage {
    padding: 0;
}

.homepage-text {
    padding: 22% 4% 40%;
    /* font-weight: bold; */
}

.homepage-intro {
    font-size: 18px;
}

.homepage-title h3 {
    padding-bottom: 3%;
    text-transform: capitalize;
}

.search-guest-button2 {
    width: 100%;
    border-radius: 0;
    background-color: #323232;
    color: white;
    font-size: 18px;
}

.review-guest-button {
    margin-top: 5%;
    width: 100%;
    border: 5px;
    border-radius: 5%;
    border-color: #0b0b0b;
}

.search-guest-button2:hover,
.review-guest-button:hover {
    color: white;
    background-color: #0b0b0b;
}

.homepage-buttons {
    padding-top: 22%;
}

@media screen and (min-width: 370px) {
    .homepage-text {
        padding: 22% 6% 40%;
    }

    .review-guest-button,
    .search-guest-button2 {
        background-color: #323232;
        color: white;
    }

    .review-guest-button {
        margin-top: 5%;
    }
}

@media screen and (min-width: 370px) {
    .homepage-text {
        padding: 15% 6% 40%;
    }

    .homepage-intro {
        padding: 0 5% 0 0;
    }

    .homepage-buttons {
        padding-top: 15%;
    }
}

@media screen and (min-width: 420px) {
    .homepage-image {
        background: url('../img/pexels-charlotte-may-5825529.jpg');
        /* height: auto; */
        opacity: 95%;
        background-position: bottom;
        background-size: cover;
        background-repeat: no-repeat;
        height: 20vh;
        visibility: visible;
    }

    .homepage-text {
        padding: 15% 8% 18%;
    }
}

@media screen and (min-width: 540px) {
    .homepage-text {
        padding: 12% 10%;
    }

    .homepage-intro {
        padding: 0 12% 0% 0;
    }

    .review-guest-button,
    .search-guest-button2 {
        background-color: #323232;
        color: white;
    }

    .review-guest-button {
        margin-top: 5%;
    }
}

@media screen and (min-width: 700px) {
    .review-guest-button,
    .search-guest-button2 {
        background-color: #323232;
        color: white;
    }

    .review-guest-button {
        margin-top: 4%;
    }

    .homepage-buttons {
        padding-top: 2%;
    }
}

@media screen and (min-width: 768px) {
    .homepage-image {
        background: url('../img/pexels-charlotte-may-5825529.jpg');
        /* height: auto; */
        opacity: 95%;
        background-position: bottom;
        background-size: cover;
        background-repeat: no-repeat;
        height: 80vh;
        visibility: visible;
    }

    .homepage-text {
        padding: 15% 5%;
    }

    .homepage-intro {
        padding: 0 5% 8% 0;
    }

    .review-guest-button {
        background-color: #323232;
        color: white;
        width: 100%;
        font-size: 15px;
    }
}

@media screen and (min-width: 992px) {
    .homepage-text {
        padding: 15% 12% 0%;
    }

    .homepage-intro {
        font-size: 20px;
    }

    .review-guest-button,
    .search-guest-button2 {
        background-color: #323232;
        color: white;
    }

    .review-guest-button {
        margin-top: 5%;
    }
}

@media screen and (min-width: 1200px) {
    .review-guest-button,
    .search-guest-button2 {
        background-color: #323232;
        color: white;
        width: 70%;
    }

    .review-guest-button {
        margin-top: 5%;
    }
}

@media screen and (min-width: 1400px) {
}

@media screen and (max-width: 566px) and (max-height: 768px) and (orientation: landscape) {
}
