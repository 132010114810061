.login-form-container {
    position: relative;
    height: 90vh;
    max-width: 100%;
    filter: opacity(0.9);
    background-position: right;
    background-size: auto;
    background-repeat: no-repeat;
}

.login-form {
    padding: 5%;
}

.login-header {
    padding-bottom: 2%;
}

.loginform-link-signup {
    padding: 5% 0;
}

.reset-password-link {
    float: right;
    padding: 0;
}
@media screen and (min-width: 768px) {
    .login-form {
        padding: 6% 0 0 12%;
    }

    .login-form-container {
        background: url('../img/chair.png');
        background-repeat: no-repeat;
    }
}

@media screen and (min-width: 992px) {
    .login-form-container {
        background: url('../img/chair.png');
        position: relative;
        height: 100vh;
        max-width: 100%;
        filter: opacity(0.9);
        background-position: right;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

@media screen and (min-width: 1200px) {
}

@media screen and (min-width: 1400px) {
    .login-form {
        padding: 6% 0 0 16%;
    }
}

@media screen and (max-width: 566px) and (max-height: 768px) and (orientation: landscape) {
}
